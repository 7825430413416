import './style.styl'
import { addSplideClasses, connectSplideArrows, sel } from './utils'
import Home from './home'
import Form from './form'
import Modal from './modal'
import Thank from './thank'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Lenis from '@studio-freight/lenis'
import Splide from '@splidejs/splide'

const lenis = new Lenis()
lenis.on('scroll', ScrollTrigger.update)
gsap.ticker.add((time) => {
  lenis.raf(time * 1000)
})
gsap.ticker.lagSmoothing(0)

const _dataPage = sel('.page-wrapper') || sel('body')
const dataPage = _dataPage?.getAttribute('data-page')
switch (dataPage) {
  case 'home':
    Home()
    break
  case 'thank':
    Thank()
    break

  case 'error':
    Error()
    break
  case 'form':
    Form()
    break
  default:
    console.log('unknown data-page:', dataPage)
}

Modal()
// Testimonials slider
;(() => {
  const prefix = 'testimonials'
  const el = '.' + prefix + '__slider'
  addSplideClasses(el)

  const slider = new Splide(el, {
    arrows: false,
    pagination: false,
    gap: '3rem',
    perPage: 1,
    loop: true,
    speed: 1500,
    easing: 'cubic-bezier(0.16, 1, 0.3, 1)',
  }).mount()
  connectSplideArrows(slider, '#test-arrows')
  // connectSplideBullets(slider, '#test-pg')
})()
